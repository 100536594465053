import React from "react";
import "../../assets/css/modal.css";
import { Modal, Grid } from "@material-ui/core";
import WaterMelon from "../../assets/images/Modern-Pulp-WatermelonGirl_stars.PNG";
import PropTypes from "prop-types";

const Modal2 = ({ initialValue, setIsModalOpen, open }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <React.Fragment>
      <Grid container justify="center" direction="row" alignItems="center">
        <Modal open={open} onClose={handleClose}>
          <Grid container justify="space-evenly">
            <Grid item>
              <img
                src={initialValue}
                style={{
                  // marginTop: 100,
                  // marginLeft: 800,
                  maxHeight: 600,
                  maxWidth: 600,
                }}
              />
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </React.Fragment>
  );
};

Modal2.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    imageUrl: PropTypes,
  }),
};

export default Modal2;
