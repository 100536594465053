import React, { useState } from "react";
import logo from "../assets/images/Sunpoplogostamp.png";
import {
  IconButton,
  Fab,
  Drawer,
  Card,
  Button,
  Tooltip,
  Container,
  Select,
  List,
  ListItem,
  Divider,
  Grid,
} from "@material-ui/core";
import github from "../assets/images/icons/github.png";
import instagram from "../assets/images/icons/instagram.png";
import linkedin from "../assets/images/icons/linkedin.png";
import sunpop from "../assets/images/Sunpoplogostampnotext.jpg";
import { Link } from "react-router-dom";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import "./style.css";

const Nav = () => {
  const [state, setState] = React.useState({
    right: false,
    display: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const about =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/AboutText.png";

  const collectionsFont =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/CollectionsText.png";

  const commissionsColor =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/CommissionsColorText.png";

  const commissions =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/CommissionsText.png";

  const fruityBabesColor =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/FruityBabesColorText.png";

  // const fruityBabes =
  //   "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/FruityBabesText.png";

  const alienBabes =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/AlienBabesText.png";

  const otherIllosColor =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/OtherIllosColorText.png";

  const otherIllos =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/OtherIllos.png";

  const collections = ["fruity babes", "other illos"];

  const etsy =
    "http://vectorlogofree.com/wp-content/uploads/2014/02/49313-etsy-logo-icon-vector-icon-vector-eps.png";

  const resume =
    "https://cdn2.iconfinder.com/data/icons/identificon/96/cv-512.png";

  const purpleFlower =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/PurpleFlowerStamp1.png";
  const yellowFlower =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/YellowFlowerStamp1.png";
  const greenFlower =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/GreenFlowerStamp1.png";
  const blueFlower =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/BlueFlowerStamp1.png";
  const orangeFlower =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/OrangeFlowerStamp1.png";

  const multi1 =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/MultiColorFlowerStamp1.png";
  const multi2 =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/MultiColorFlowerStamp2.png";
  const multi3 =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/MultiColorFlowerStamp3.png";

  return (
    <React.Fragment>
      {/* <Container className="d-flex" fixed> */}
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        className="navBar"
        style={{ backgroundColor: "white" }}
      >
        <Grid item style={{ marginTop: 45 }}>
          <div className="header-nav-menu d-none d-lg-block"></div>
        </Grid>
        <Grid item>
          {/* <div> */}
          <span className="d-block d-md-none justify-content-center">
            <Fab
              title="menu"
              style={{ marginTop: 40, marginleft: 10 }}
              onClick={toggleDrawer("right", true)}
              color="secondary"
              size="medium"
              alt="View More"
            >
              <MenuRoundedIcon />
            </Fab>
          </span>

          {/* </div> */}
        </Grid>
        <Grid item>
          <Link to="/" title="SunPopShop">
            <i>
              <img
                className="round"
                alt="SunPopShop"
                src={logo}
                style={{ height: 80, width: 80, marginTop: 20 }}
              />
            </i>
          </Link>
        </Grid>
        <Drawer
          style={{ marginRight: 20, marginTop: 10, padding: 50 }}
          variant="temporary"
          anchor="left"
          open={state.right}
          onClose={toggleDrawer("right", false)}
          elevation={11}
        >
          <List className="py-0">
            <ListItem className="d-block bg-secondary py-2 px-3">
              <div
                className="d-flex w-100 justify-content-between navbar-light align-content-center"
                style={{ marginRight: 20, marginTop: 10 }}
              >
                <div
                  className="header-nav-logo justify-content-start"
                  style={{ marginRight: 20 }}
                >
                  <a
                    style={{ marginRight: 20, marginTop: 10 }}
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="navbar-brand d-flex align-items-center d-40"
                    title="..."
                  ></a>
                </div>
                <Grid item>
                  <div className="nav-logo">
                    <Link to="/" title="SunPopShop">
                      <i className="bg-white">
                        <img
                          alt="SunPopShop"
                          src={logo}
                          style={{
                            height: 100,
                            width: 100,
                            marginLeft: 90,
                            marginBottom: 10,
                          }}
                        />
                      </i>
                    </Link>
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className="row"
                    style={{ marginBottom: 25, marginLeft: 30 }}
                  >
                    {/* <Tooltip arrow title="Etsy">
                      <IconButton
                        className="nav-link text-white text-bold"
                        href="https://www.etsy.com/shop/SunPopShop"
                        rel="noopener nofollow"
                        target="_blank"
                      >
                        <span className="btn-wrapper--icon">
                          <img
                            style={{
                              height: 40,
                              width: 40,
                            }}
                            src={etsy}
                          />
                        </span>
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip arrow title="Github">
                      <IconButton
                        className="nav-link text-white text-bold"
                        href="https://github.com/Becca-Guertin"
                        rel="noopener nofollow"
                        target="_blank"
                      >
                        <span className="btn-wrapper--icon">
                          <img
                            style={{
                              height: 40,
                              width: 40,
                            }}
                            src={github}
                          />
                        </span>
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow title="LinkedIn">
                      <IconButton
                        className="nav-link text-white text-bold"
                        href="https://www.linkedin.com/in/rebecca-guertin/"
                        rel="noopener nofollow"
                        target="_blank"
                      >
                        <span className="btn-wrapper--icon">
                          <img
                            style={{
                              height: 40,
                              width: 40,
                            }}
                            src={linkedin}
                          />
                        </span>
                      </IconButton>
                    </Tooltip>
                    <Tooltip arrow title="Instagram">
                      <IconButton
                        className="nav-link text-white text-bold"
                        href="https://www.instagram.com/sunflowerpopshop/"
                        rel="noopener nofollow"
                        target="_blank"
                      >
                        <span className="btn-wrapper--icon">
                          <img
                            style={{
                              height: 50,
                              width: 50,
                            }}
                            src={instagram}
                          />
                        </span>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </div>
            </ListItem>
            <Divider />
            <ListItem className="d-block py-3 px-2">
              <Link
                title="about"
                to="/about"
                className="d-flex px-2 align-items-center dropdown-item rounded"
                style={{
                  color: "black",
                  fontSize: 47,
                  fontFamily: "Brush Script MT",
                }}
              >
                <div className="align-box-row w-100">
                  <div className="mr-3">
                    <div className="bg-strong-bliss text-center text-white d-40 rounded-circle"></div>
                  </div>
                  <div className="text-truncate max-w-70 overflow-hidden">
                    <div
                      className="font-weight-bold text-primary d-block"
                      style={{ textAlign: "center" }}
                    >
                      <i className="bg-white">
                        <img
                          alt=""
                          src={multi2}
                          style={{ height: 60, width: 60, marginRight: 20 }}
                        />
                      </i>
                      <img
                        src={about}
                        style={{ maxHeight: 100, maxWidth: 115 }}
                      ></img>
                    </div>
                    <span className="text-black-50"></span>
                  </div>
                </div>
              </Link>
            </ListItem>
            <Divider />
            <ListItem className="d-block py-3 px-2">
              <div
                className="dropdown"
                style={{
                  color: "black",
                  fontSize: 45,
                  fontFamily: "Brush Script MT",
                }}
              >
                <div className="btn dropdown-toggle">
                  <i className="bg-white">
                    <img
                      alt=""
                      src={multi1}
                      style={{ height: 60, width: 60, marginRight: 20 }}
                    />
                  </i>
                  <img
                    src={collectionsFont}
                    style={{ maxHeight: 100, maxWidth: 185 }}
                  ></img>
                </div>
                <Divider />
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <i className="bg-white">
                        <img
                          alt=""
                          src={sunpop}
                          style={{
                            height: 35,
                            width: 35,
                            marginRight: 20,
                            marginTop: 10,
                            marginLeft: 25,
                          }}
                        />
                      </i>
                      <Link
                        title="commissions"
                        to="/collections/commissions"
                        style={{
                          fontSize: 35,
                          color: "black",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={commissions}
                          style={{
                            maxHeight: 100,
                            maxWidth: 155,
                          }}
                        ></img>
                      </Link>
                    </div>
                    <div className="col">
                      <i className="bg-white">
                        <img
                          alt=""
                          src={sunpop}
                          style={{
                            height: 35,
                            width: 35,
                            marginRight: 20,
                            marginTop: 10,
                            marginLeft: 25,
                          }}
                        />
                      </i>
                      <Link
                        title="alien babes"
                        to="/collections/alienBabes"
                        style={{
                          fontSize: 35,
                          color: "black",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={alienBabes}
                          style={{
                            maxHeight: 85,
                            maxWidth: 135,
                          }}
                        ></img>
                      </Link>
                    </div>
                    {/* <i className="bg-white">
                      <img
                        alt=""
                        src={sunpop}
                        style={{
                          height: 35,
                          width: 35,
                          marginRight: 20,
                          marginLeft: 25,
                        }}
                      />
                    </i>
                    <Link
                      title="fruity babes"
                      to="/collections/fruityBabes"
                      style={{ fontSize: 35, color: "black", marginBottom: 10 }}
                    >
                      <img
                        src={fruityBabes}
                        style={{ maxHeight: 90, maxWidth: 135 }}
                      ></img>
                    </Link> */}
                    <div className="col">
                      <i className="bg-white">
                        <img
                          alt=""
                          src={sunpop}
                          style={{
                            height: 35,
                            width: 35,
                            marginRight: 20,
                            marginLeft: 25,
                          }}
                        />
                      </i>
                      <Link
                        title="other illos"
                        to="/collections/otherIllos"
                        style={{
                          fontSize: 35,
                          color: "black",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={otherIllos}
                          style={{ maxHeight: 85, maxWidth: 120 }}
                        ></img>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </ListItem>
            <Divider />
            <Grid container direction="row" justify="center">
              <Grid item></Grid>
              <Grid item></Grid>
              <Grid item></Grid>
            </Grid>
            <Divider />
          </List>
        </Drawer>
      </Grid>
    </React.Fragment>
  );
};

export default Nav;
