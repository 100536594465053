import React from "react";
import "../../assets/css/modal.css";
import { Modal } from "@material-ui/core";
import logo from "../../assets/images/SunPopShop logo.png";
import PropTypes from "prop-types";

const Modal5 = ({ setIsModalOpen, open }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <React.Fragment>
      <div className="container justify-content-center">
        <Modal open={open} onClose={handleClose}>
          <img
            src={logo}
            style={{
              // marginTop: 100,
              // marginLeft: 600,
              maxHeight: 600,
              maxWidth: 600,
            }}
          />
        </Modal>
      </div>
    </React.Fragment>
  );
};

Modal5.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default Modal5;
