import React from "react";
import "../../assets/css/modal.css";
import { Modal } from "@material-ui/core";
//import WaterMelon from "../../assets/images/Modern-Pulp-WatermelonGirl_stars.PNG";
import PropTypes from "prop-types";

const Modal4 = ({ initialValue, setIsModalOpen, open }) => {
  //   console.log(props.babe)
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <React.Fragment>
      <div className="container justify-content-center">
        <Modal open={open} onClose={handleClose}>
          <img
            src={initialValue}
            style={{ marginTop: 100, marginLeft: 750, maxHeight: 800, maxWidth: 800 }}
          />
          {/* <h3 className="text-black" style={{ fontFamily: "Brush Script MT" }}>
            {initialValue.title}
          </h3> */}
        </Modal>
      </div>
    </React.Fragment>
  );
};

Modal4.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    imageUrl: PropTypes,
    title: PropTypes.string,
  }),
};

export default Modal4;
