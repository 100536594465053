import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal1 from "../components/Modals/Modal1.jsx";
import Modal5 from "../components/Modals/Modal5";
import Modal6 from "../components/Modals/Modal6";
//import LandingFruitTemplate from "../components/LandingFruitTemplate";
import { Grid } from "@material-ui/core";
import flowerLogo2 from "../assets/images/Image from iOS (15).png";
import sunpopFlower from "../assets/images/Image from iOS (1).png";
import heart2 from "../assets/images/Image from iOS (heart2).png";
import ImageSlider from "../components/ImageSlider";

import FeaturedLandSlide from "../components/FeaturedLandSlide";
import "./style.css";

const Landing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [currentFruityFriend, setCurrentFruityFriend] = useState();

  const fruityBabes = {
    // babes: [
    //   {
    //     id: 1,
    //     imageUrl:
    //       "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/fruit/watermelon.png",
    //   },

    //   {
    //     id: 2,
    //     imageUrl:
    //       "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/blueBerryGirl.jpg",
    //   },
    //   {
    //     id: 3,
    //     imageUrl:
    //       "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/fruit/kiwi.png",
    //   },

    //   {
    //     id: 4,
    //     imageUrl:
    //       "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/fruit/orange.png",
    //   },
    // ],
    pink: "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/pink.png",
    stripes:
      "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/stripes.png",
    featuredFont:
      "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/FeaturedColorText2Crop.png",
  };

  // const mapFruitBabes = (babe) => {
  //   return (
  //     <LandingFruitTemplate
  //       key={`FruitBabes-${babe.id}`}
  //       babe={babe}
  //       handleClick={handleClick}
  //       imageUrl={fruityBabes.babes.imageUrl}
  //     />
  //   );
  // };

  // const handleClick = (e, res) => {
  //   console.log(e, res);
  //   let babe = e.imageUrl;
  //   setIsModalOpen(true);
  //   setCurrentFruityFriend(babe);
  // };

  const handleClick3 = () => {
    setIsModalOpen3(true);
  };

  return (
    <React.Fragment>
      <div
        className="container"
        style={{
          padding: 100,
          backgroundImage: "url(" + fruityBabes.pink + ")",
        }}
      >
        <div
          className="container"
          // style={{
          //   backgroundImage:
          //     "url(" +
          //     "https://sunpopbucket.s3.us-west-1.amazonaws.com/images/Opposition+shirt_v1SP2020.jpg" +
          //     ")",
          // maxWidth: 2000,
          // maxHeight: 1000,
          // backgroundSize: "cover",
          // backgroundSize: "100% 150%",
          // backgroundRepeat: "no-repeat",

          // paddingTop: 100,
          // paddingBottom: 100,
          // paddingTop: 1000,
          // }}
        >
          <Grid
            container
            direction="column"
            spacing={6}
            justify="center"
            alignItems="center"
            style={{
              marginTop: 50,
              // backgroundImage:
              //   "url(" +
              //   "https://sunpopbucket.s3.us-west-1.amazonaws.com/images/Opposition+shirt_v1SP2020.jpg" +
              //   ")",
              backgroundSize: "100% 150%",
              backgroundRepeat: "no-repeat",
            }}
            //className="containerPink"
          >
            {/* <Grid item>
              <img
                className="roundCorners  zoom"
                // title="click for more info"
                src={
                  "https://sunpopbucket.s3.us-west-1.amazonaws.com/images/HelloWorldColorTextCrop.png"
                }
                style={{
                  height: "auto",
                  width: "auto",
                  // maxHeight: 300,
                  // maxWidth: 300,
                }}
              ></img>
            </Grid> */}
            {/* <Grid container direction="row" spacing={4} justify="center">
              <Grid item>
                <img
                  className="roundCorners shadow zoom hoverHand"
                  style={{ maxHeight: 400, maxWidth: 300 }}
                  src={
                    "https://sunpopbucket.s3.us-west-1.amazonaws.com/images/SelfieBuddiesA.png"
                  }
                  alt="@sunflowerpopshop"
                />
              </Grid>
              <Grid item>
                <Link to="/about" title="click for more about me!">
                  <img
                    className="roundCorners shadow zoom"
                    src={
                      "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/HelloMyNameIsTexture.png"
                    }
                    style={{
                      height: "auto",
                      width: "auto",
                      maxHeight: 300,
                      maxWidth: 300,
                    }}
                  ></img>
                </Link>
              </Grid>
            </Grid> */}
            <Grid
              container
              direction="row"
              spacing={6}
              justify="center"
              alignItems="center"
            >
              <Grid item={2}>
                <img
                  src={heart2}
                  style={{ maxHeight: 100, maxWidth: 100 }}
                  alt=""
                />
              </Grid>
              <Grid item={4}>
                <Link to="/about" title="click for more info about me!">
                  <img
                    class="zoom logo"
                    src={flowerLogo2}
                    style={{ maxHeight: 300, maxWidth: 300 }}
                    alt=""
                  />
                </Link>
              </Grid>
              <Grid item={2}>
                <img
                  src={heart2}
                  style={{ maxHeight: 100, maxWidth: 100 }}
                  alt=""
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item>
                  <img
                    className="shadow roundCorners zoom"
                    src={
                      "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/Bending+backwards_NoBckg2.jpg"
                    }
                    style={{ maxWidth: 300, maxHeight: 500 }}
                  />
                </Grid>
                <Grid item className="justify-content-flex-end">
                  <div className="box">
                    <img
                      className=" shadow roundCorners zoom"
                      style={{ maxWidth: 350, maxHeight: 550 }}
                      src={
                        "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/SunPopShop+logo.png"
                      }
                    ></img>
                  </div>
                </Grid>
                <Grid item>
                  <img
                    src={
                      "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/SunpopShapesNPeople.PNG"
                    }
                    style={{ maxWidth: 300, maxHeight: 500 }}
                    className="shadow roundCorners zoom"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item={2}>
            <img className="logo2" src={heart2} />
          </Grid>
          <Grid item={4}>
            <a href="/about">
              <img
                className="logo"
                src={flowerLogo2}
                title="click for more about"
              />
            </a>
          </Grid>
          <Grid item={2}>
            <img className="logo2" src={heart2} />
          </Grid> */}
            {/* <Grid item>
            <img
              className="featuredFlower"
              src={sunpopFlower}
              style={{ maxHeight: 150, maxWidth: 150, boxShadow: "inherit" }}
            />
          </Grid> */}
            {/* <Grid item>
              <img
                src={fruityBabes.featuredFont}
                style={{ maxHeight: 285, maxWidth: 385 }}
              ></img>
            </Grid>
            <Grid item>
              <FeaturedLandSlide />
            </Grid> */}
            {/* <Grid item>
            <img
              className="featuredFlower"
              src={sunpopFlower}
              style={{ maxHeight: 150, maxWidth: 150, boxShadow: "inherit" }}
            />
          </Grid> */}
          </Grid>
        </div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={4}
          style={{ padding: 20, marginTop: 20 }}
        >
          <Grid item>
            {/* <img
              src={
                "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/MultiColorFlowerStamp1.png"
              }
              style={{ maxHeight: 125, maxWidth: 125, boxShadow: "inherit" }}
            /> */}
          </Grid>
          <Grid item>
            {/* <a href="/about"> */}

            {/* </a> */}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
          style={{
            padding: 20,
            // backgroundColor: "white",
            // marginTop: 20,
            // marginBottom: 40,
            // maxWidth: "50%",
          }}
        >
          {/* <Modal1
              open={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              initialValue={currentFruityFriend}
            />

            {fruityBabes.babes.map(mapFruitBabes)} */}

          <Modal5 open={isModalOpen2} setIsModalOpen={setIsModalOpen2} />
          <Modal6 open={isModalOpen3} setIsModalOpen={setIsModalOpen3} />
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="row"
          spacing={4}
        >
          <Grid item></Grid>
          {/* <Grid item>
            <img
              className="shadow roundCorners zoom"
              src={
                "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/Bending+backwards_NoBckg2.jpg"
              }
              style={{ maxWidth: 300, maxHeight: 500 }}
            />
          </Grid>
          <Grid item className="justify-content-flex-end">
            <div className="box">
              <img
                className=" shadow roundCorners zoom"
                style={{ maxWidth: 350, maxHeight: 550 }}
                src={
                  "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/SunPopShop+logo.png"
                }
              ></img>
            </div>
          </Grid>
          <Grid item>
            <img
              src={
                "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/SunpopShapesNPeople.PNG"
              }
              style={{ maxWidth: 300, maxHeight: 500 }}
              className="shadow roundCorners zoom"
            />
          </Grid> */}
          <Grid item></Grid>

          <Grid container justify="center">
            <ImageSlider />
          </Grid>

          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
              spacing={6}
            >
              <Grid
                container
                className="shadow"
                style={{
                  backgroundImage: "url(" + fruityBabes.stripes + ")",
                  padding: 80,
                }}
              >
                <Grid item>
                  <Link to="/collections/alienbabes">
                    <div className="box">
                      <img
                        className="shadow roundCorners image"
                        src={
                          "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/Modern-Pulp-alienSoulSister.PNG"
                        }
                        style={{
                          marginTop: 30,
                          marginLeft: 20,
                          height: "auto",
                          width: "auto",
                          maxHeight: 370,
                          maxWidth: 370,
                        }}
                      ></img>
                      <div className="middle">
                        <div className="text">click to see more like me!</div>
                      </div>
                    </div>
                  </Link>
                </Grid>

                <Grid item>
                  <div className="box hoverHand" onClick={handleClick3}>
                    <img
                      className="image btn shadow roundCorners"
                      src={
                        "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/girls/blueBerry2.PNG"
                      }
                      style={{
                        marginTop: 30,
                        marginLeft: 20,
                        height: "auto",
                        width: "auto",
                        maxHeight: 370,
                        maxWidth: 370,
                      }}
                    />
                    <div className="middle">
                      <div className="text hoverHand">click me</div>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <Link to="/collections/alienbabes">
                    <div className="box">
                      <img
                        className="shadow roundCorners image"
                        src={
                          "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/girls/strawberry2.PNG"
                        }
                        style={{
                          marginTop: 30,
                          marginLeft: 20,
                          height: "auto",
                          width: "auto",
                          maxHeight: 370,
                          maxWidth: 370,
                        }}
                      ></img>
                      <div className="middle">
                        <div className="text">click to see more like me!</div>
                      </div>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default Landing;
