import React, { useState } from "react";
import { Grid } from "@material-ui/core";
//import { IconButton } from "@material-ui/core";
import Modal7 from "../components/Modals/Modal7";
import Modal8 from "../components/Modals/Modal8";
import "./style.css";

const About = () => {
  const [isModalOpen7, setIsModalOpen] = useState(false);
  const [isModalOpen8, setIsModalOpen2] = useState(false);
  const cloud =
    "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/Modern-Pulp-cloudBG.PNG";

  const handleClick = () => {
    setIsModalOpen(true);
  };
  const handleClick2 = () => {
    setIsModalOpen2(true);
    // alert("Press Esc to return to About");
  };
  return (
    <React.Fragment>
      <div
        className="container flex-extend"
        style={{
          padding: 100,
          backgroundImage: "url(" + cloud + ")",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={4}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-start"
            spacing={4}
          >
            <Grid item xs={3}>
              <a href="/">
                <img
                  src="https://sunpopbucket.s3.us-west-1.amazonaws.com/images/BackHomeLogoCrop.png"
                  className="logoAnimate backHome"
                  style={{ marginTop: 50 }}
                ></img>
              </a>
            </Grid>
          </Grid>
          <Grid item>
            <img
              className="roundCorners shadow zoom hoverHand"
              style={{ maxHeight: 400, maxWidth: 300 }}
              src={
                "https://sunpopbucket.s3.us-west-1.amazonaws.com/images/SelfieBuddiesA.png"
              }
              alt="@sunflowerpopshop"
              onClick={handleClick}
            />
            <Modal7
              open={isModalOpen7}
              setIsModalOpen={setIsModalOpen}
            ></Modal7>
            <Modal8
              open={isModalOpen8}
              setIsModalOpen={setIsModalOpen2}
            ></Modal8>
          </Grid>
          <Grid item>
            <img
              className="roundCorners shadow zoom"
              src={
                "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/HelloMyNameIsTexture.png"
              }
              style={{
                height: "auto",
                width: "auto",
                maxHeight: 300,
                maxWidth: 300,
              }}
            ></img>
          </Grid>
          <Grid item className="justify-content-center">
            {/* <IconButton
              href="https://sunpopbucket.s3.us-west-1.amazonaws.com/images/GuertinRebeccaResumeNov2021.pdf"
              rel="noopener nofollow"
              target="_blank"
            > */}
            <img
              className="round hoverHand2"
              src={
                "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/checkOutResume.png"
              }
              style={{ maxHeight: 200, maxWidth: 200 }}
              onClick={handleClick2}
            ></img>
            {/* </IconButton> */}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <img
              className="roundCorners shadow zoom"
              src={
                "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/SkillsNewTexture.png"
              }
              style={{
                height: "auto",
                width: "auto",
                maxHeight: 300,
                maxWidth: 300,
              }}
            ></img>
          </Grid>
          <Grid item>
            <img
              className="roundCorners shadow zoom"
              src={
                "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/AboutMeEditTex.png"
              }
              style={{ maxHeight: 300, maxWidth: 300 }}
            ></img>
          </Grid>
          <Grid item>
            <img
              className="roundCorners shadow zoom"
              src={
                "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/ContactSP.png"
              }
              style={{ maxHeight: 300, maxWidth: 300 }}
            ></img>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item xs={6}></Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default About;
