import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import Landing from "./components/Landing";
import NavBar from "./components/NavBar";
import Collections from "./components/Collections";
import About from "./components/About";
//import FruityBabes from "./components/FruityBabes";
import BlueBerryGal from "./components/BlueBerryGal";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import SunPopShop from "./components/SunPop";
import Logos from "./components/Logos";
import LogoDetail from "./components/LogoDetail";
import Splash from "./components/Splash";
import Commissions from "./components/Commissions";
import ImageSlider from "./components/ImageSlider";
import AlienBabes from "./components/AlienBabes";
import HazardsAndHijinksDetail from "./components/HazardsAndHijinksDetail";
import "./App.css";

class App extends Component {
  componentDidUpdate(prevProps) {
    let currentPath = this.props.location.pathname;
    let previousPath = prevProps.location.pathname;

    console.log("App", { currentPath, previousPath });
  }
  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <NavBar {...this.props} />
          <main role="main">
            <div>
              <Route
                path="/collections"
                exact={true}
                component={Collections}
              ></Route>
              <Route path="/" exact={true} component={Landing}></Route>
              <Route path="/about" exact={true} component={About}></Route>
              {/* <Route
                path="/collections/fruityBabes"
                exact={true}
                component={FruityBabes}
              ></Route> */}
              <Route
                path="/blueBerryGal/:id"
                exact={true}
                component={BlueBerryGal}
              ></Route>
              <Route path="/contact" exact={true} component={Contact}></Route>
              <Route
                path="/collections/otherIllos"
                exact={true}
                component={SunPopShop}
              ></Route>
              <Route path="/logos" exact={true} component={Logos}></Route>
              <Route
                path="/logos/:id"
                exact={true}
                component={LogoDetail}
              ></Route>
              <Route path="/splash" exact={true} component={Splash}></Route>
              <Route
                path="/collections/commissions"
                exact={true}
                component={Commissions}
              ></Route>
              <Route
                path="/collections/alienBabes"
                exact={true}
                component={AlienBabes}
              ></Route>
              <Route
                path="/collections/commissions/hazards"
                exact={true}
                component={HazardsAndHijinksDetail}
              ></Route>
            </div>
            <Footer />
          </main>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
