import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";

const LogoTemplate = (props) => {
  const onHandleClick = () => {
    props.handleClick(props.logo);
  };

  return (
    <div className="col-md">
      <Card className="card-box-hover-rise mb-4 justify-content-center">
        <CardContent className="p-3">
          <div className="bt" onClick={onHandleClick}>
            <img
              style={{
                height: "auto",
                width: "auto",
                maxHeight: 500,
                maxWidth: 300,
                marginBottom: 30,
              }}
              src={props.logo.imageUrl}
              className="card-img-top md-3 bg-wrapper rounded"
              alt="Blueberry Gal"
            ></img>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

LogoTemplate.propTypes = {
  logo: PropTypes.shape({
    imageUrl: PropTypes,
  }),
};

export default LogoTemplate;
