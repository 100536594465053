import React from "react";
import "../../assets/css/modal.css";
import { Modal } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const Modal7 = ({ setIsModalOpen, open }) => {
  const handleClose = () => {
    setIsModalOpen(false);
  };
  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="center" spacing={4}>
        <Grid item xs={4}>
          <Modal
            //style={{ justifyContent: "center" }}
            open={open}
            onClose={handleClose}
          >
            <img
              src={
                "https://sunpopbucket.s3.us-west-1.amazonaws.com/images/SelfieBuddiesA.png"
              }
              style={{
                margin: 100,
                marginLeft: 500,
                maxHeight: 600,
                maxWidth: 400,
              }}
            />
          </Modal>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Modal7.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
};

export default Modal7;
