import React from "react";
import background from "../assets/images/Modern-Pulp-cloudBG.PNG";
import PropTypes from "prop-types";

const BlueBerryGal = (props) => {
  const babe = props.location.state.payload;

  const redirectToFruityBabes = () => {
    props.history.push("/fruityBabes");
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundImage: "url(" + background + ")",
        marginLeft: 350,
        marginTop: 30,
      }}
      className="container"
    >
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col md-6" style={{ marginLeft: 200 }}>
          <img style={{ height: 725, width: 750 }} src={babe.imageUrl} />

          <div style={{ fontFamily: "Brush Script MT" }}>
            <div className="row">
              <h1>
                <button
                  type="button"
                  onClick={redirectToFruityBabes}
                  className="btn btn-outline-info text-size-xxl"
                  style={{
                    marginLeft: 17,
                    marginRight: 17,
                    fontFamily: "Brush Script MT",
                  }}
                >
                  back to fruity babes
                </button>

                {babe.title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BlueBerryGal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      payload: PropTypes.shape({
        imageUrl: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default BlueBerryGal;
