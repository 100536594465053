import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Grid } from "@material-ui/core";
import "./style.css";

const SunPopIlloTemplate = (props) => {
  const onHandleClick = () => {
    props.handleClick(props.sunPop);
  };

  return (
    <div className="col-md">
      <Card className="card-box-hover-rise mb-4 justify-content-center">
        <CardContent className="p-3 justify-center">
          <div className="bt" onClick={onHandleClick}>
            <div className="box">
              <img
                style={{
                  height: "auto",
                  width: "auto",
                  maxHeight: 475,
                  maxWidth: 290,
                  marginBottom: 30,
                }}
                src={props.sunPop.imageUrl}
                className="image rounded"
                alt="..."
              ></img>
              <h3
                style={{ fontFamily: "Brush Script MT" }}
                className="text-black"
              ></h3>
              <div className="middle">
                <div className="text">{props.sunPop.title}</div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

SunPopIlloTemplate.propTypes = {
  sunPop: PropTypes.shape({
    imageUrl: PropTypes,
    title: PropTypes,
  }),
  handleClick: PropTypes.func,
};

export default SunPopIlloTemplate;
