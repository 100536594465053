import React, { useHistory, useState } from "react";
import LogoTemplate from "../components/LogoTemplate";
import Modal4 from "../components/Modals/Modal4";
import { Grid } from "@material-ui/core";

const Logos = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLogo, setCurrentLogo] = useState();

  const logos = {
    logo: [
      {
        id: 1,
        title: "Retro 70's Logo/Watermark, 2021",
        imageUrl:
          "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/SunPopLogoStampOG.png",
      },

      {
        id: 2,
        title: " Galaxy Logo/Watermark 2021",
        imageUrl:
          "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/Sun+pop+logo+stamp_galaxyC.png",
      },
      {
        id: 3,
        title: "Retro 70's Logo/Watermark, 2021",
        imageUrl:
          "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/Krafty-Kuts-green+logo+stamp.png",
      },

      {
        id: 5,
        title: "Retro 70's Logo/Watermark, 2021",
        imageUrl:
          "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/Modern-Pulp-sunpoplogo_prpl.png",
      },
    ],
    backGround:
      "https://sunpopbucket.s3-us-west-1.amazonaws.com/images/PinkBackGround.PNG",
  };

  const mapLogos = (logo) => {
    return (
      <LogoTemplate
        key={`FruitBabes-${logo.id}`}
        logo={logo}
        imageUrl={logos.logo.imageUrl}
        title={logos.logo.title}
        handleClick={handleClick}
      />
    );
  };

  const handleClick = (e, res) => {
    console.log(e, res);
    let logo = e.imageUrl;
    setIsModalOpen(true);
    setCurrentLogo(logo);
  };
  //WORK IN PROGRESS----------------------------->
  // const redirectToDetails = (alogo) => {
  //     props.history.push(`/logos/${alogo.id}`, {
  //         type: "LOGO_DATA",
  //         payload: alogo,
  //     })
  // }
  //------------------------------------------->

  return (
    <React.Fragment>
      <div
        className="container"
        style={{
          padding: 40,
          backgroundImage: "url(" + logos.backGround + ")",
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          spacing={2}
        >
          {logos.logo.map(mapLogos)}
        </Grid>
        <Modal4
          open={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          initialValue={currentLogo}
        ></Modal4>
      </div>
    </React.Fragment>
  );
};
export default Logos;
